import { Box, Button, Stack } from '@mui/material';
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { RHFTextField } from '#/components/shared/hook-form';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import {
  invalidateSuccessorQueries,
  useDeleteInvitation,
  useGetInvitationsList,
} from '#/api/successor';
import { Successor } from '#/types/user';

type SuccessorInputProps = {
  index: number;
  remove: UseFieldArrayRemove;
  id: number;
  showDeleteButton: (email: string) => boolean | undefined;
};

export default function SuccessorInput({
  index,
  remove,
  id,
  showDeleteButton,
}: SuccessorInputProps) {
  const { translate } = useLocales();
  const { watch } = useFormContext();

  const { data: list } = useGetInvitationsList('SUCCESSOR');
  const { mutateAsync: deleteInvitation, isLoading } = useDeleteInvitation(id);

  const handleDeleteInvitation = async () => {
    await deleteInvitation(id)
      .then(() => {
        remove(index);
        invalidateSuccessorQueries.getInvitationsList();
      })
      .catch(() => {
        remove(index);
      });
  };

  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ marginBottom: '2em' }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            sm: 544,
          },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <RHFTextField
            name={`invitations[${index}].first_name`}
            label={String(translate('global.formLabels.firstName'))}
            disabled={
              list?.some(
                (invitation: Successor) =>
                  invitation?.first_name ===
                  watch(`invitations[${index}].first_name`)
              ) && true
            }
          />
          <RHFTextField
            name={`invitations[${index}].last_name`}
            label={String(translate('global.formLabels.lastName'))}
            disabled={
              list?.some(
                (invitation: Successor) =>
                  invitation?.last_name ===
                  watch(`invitations[${index}].last_name`)
              ) && true
            }
          />
        </Stack>
      </Box>

      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={1}
        sx={{
          width: {
            xs: '100%',
            sm: 544,
          },
        }}
      >
        <RHFTextField
          name={`invitations[${index}].email`}
          label="Email"
          disabled={
            list?.some(
              (invitation: Successor) =>
                invitation?.email === watch(`invitations[${index}].email`)
            ) && true
          }
        />

        {showDeleteButton(watch(`invitations[${index}].email`) as string) && (
          <Button
            type="button"
            size="small"
            color="error"
            onClick={handleDeleteInvitation}
          >
            {isLoading ? (
              <Iconify
                icon="line-md:loading-twotone-loop"
                width={20}
                height={20}
              />
            ) : (
              <Iconify
                icon="material-symbols:delete-outline"
                width={20}
                height={20}
              />
            )}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
