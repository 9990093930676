import { Button, Popover, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { BlockPicker } from 'react-color';
import Iconify from '#/components/shared/ui/Iconify';
import { RHFTextField } from '#/components/shared/hook-form';

type Props = {
  name: string;
  label: string;
};

export default function ColorPicker({ name, label }: Props) {
  const [color, setColor] = React.useState('');

  const [colorAnchor, setColorAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  const openPicker = Boolean(colorAnchor);

  const { watch, setValue } = useFormContext();

  const handleOpenColorAnchor = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setColorAnchor(event.currentTarget);
  };

  const handleCloseColorAnchor = () => {
    setColorAnchor(null);
  };

  useEffect(() => {
    if (watch(name)) {
      setColor(watch(name));
    }
    // eslint-disable-next-line
  }, [watch(name)]);

  return (
    <Stack direction="column" spacing={3}>
      <RHFTextField
        name={name}
        label={label}
        InputProps={{
          startAdornment: (
            <Button onClick={handleOpenColorAnchor}>
              <Iconify
                icon="teenyicons:circle-solid"
                width={24}
                height={24}
                color={color}
              />
            </Button>
          ),
        }}
      />
      <Popover
        open={openPicker}
        anchorEl={colorAnchor}
        onClose={handleCloseColorAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <BlockPicker
          color={color}
          onChangeComplete={(color) => {
            setColor(color.hex);
            setValue(name, color.hex);
          }}
        />
      </Popover>
    </Stack>
  );
}
