import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Stack, Card, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import React, { useEffect } from 'react';
import { IUserAccountChangePassword } from '#/types/user';
import { FormProvider, RHFTextField } from '../../shared/hook-form';
import Iconify from '#/components/shared/ui/Iconify';
import { useChangePassword } from '#/api/userQueries';
import useLocales from '#/hooks/useLocales';

type FormValuesProps = IUserAccountChangePassword;

export default function AccountChangePassword() {
  const { translate } = useLocales();

  const {
    mutate: changePassword,
    data,
    error,
    isLoading,
  } = useChangePassword();

  const ChangePassWordSchema = Yup.object().shape({
    old_password: Yup.string().required('Old Password is required'),
    new_password: Yup.string()
      .required('New Password is required')
      .min(6, 'Password must be at least 6 characters')
      .test(
        'no-match',
        'New password must be different than old password',
        (value, { parent }) => value !== parent.old_password
      ),
    confirm_new_password: Yup.string().oneOf(
      [Yup.ref('new_password')],
      'Passwords must match'
    ),
  });

  const defaultValues = {
    old_password: '',
    new_password: '',
    confirm_new_password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ChangePassWordSchema) as any | undefined,
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const disabled = !isDirty || isSubmitting;

  const onSubmit = async (data: FormValuesProps) => {
    changePassword(data);
  };

  useEffect(() => {
    if (data) {
      toast.success(data.data?.message);
      reset(defaultValues);
    }
    if (error) {
      toast.error('Please make sure to type correct old password');
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Stack spacing={3} alignItems="flex-end" sx={{ p: 3 }}>
          <RHFTextField
            name="old_password"
            type="password"
            label={String(translate('global.formLabels.oldPassword'))}
          />

          <RHFTextField
            name="new_password"
            type="password"
            label={String(translate('global.formLabels.newPassword'))}
            helperText={
              <Stack component="span" direction="row" alignItems="center">
                <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} />{' '}
                {String(translate('global.formLabels.minimum6Characters'))}
              </Stack>
            }
          />

          <RHFTextField
            name="confirm_new_password"
            type="password"
            label={String(translate('global.formLabels.confirmPassword'))}
          />

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting || isLoading}
            disabled={disabled}
          >
            <Typography>{String(translate('global.save'))}</Typography>
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
