import { useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { FormProvider, RHFUploadAvatar } from '#/components/shared/hook-form';
import { fData } from '#/utils/formatNumber';
import { useUpdateProfilePicture } from '#/api/userQueries';
import useAuth from '#/hooks/useAuth';
import useLocales from '#/hooks/useLocales';
import useAccountSwitch from '#/hooks/useAccountSwitch';

const AccountPicture = () => {
  const [uploading, setUploading] = useState(false);

  const { user, refetch: refetchUser } = useAuth();
  const { mutateAsync: updatePPicture } = useUpdateProfilePicture();
  const { translate } = useLocales();

  const defaultValues = useMemo(
    () => ({ profile_picture: user?.profile_picture || '' }),
    [user]
  );

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append('profile_picture', data.profile_picture);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await updatePPicture(formData).then(() => {
      refetchUser();
      setUploading(false);
    });
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('profile_picture', newFile, { shouldValidate: true });
        setUploading(true);
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (uploading) {
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line
  }, [uploading]);

  // useEffect(() => {
  //   if (user) {
  //     reset(defaultValues);
  //   }
  //   // eslint-disable-next-line
  // }, [user]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <RHFUploadAvatar
        name="profile_picture"
        maxSize={10000000}
        onDrop={handleDrop}
        helperText={
          <Typography
            variant="caption"
            sx={{
              mt: 2,
              mx: 'auto',
              display: 'block',
              textAlign: 'center',
              color: 'text.secondary',
            }}
          >
            {String(translate('global.imageHelper'))}
            <br /> {String(translate('global.imageMaxSize'))} {fData(10000000)}
          </Typography>
        }
      />
    </FormProvider>
  );
};

export default AccountPicture;
