import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';
import { FormProvider, RHFCheckbox } from '#/components/shared/hook-form';
import SuccessorInput from '#/components/pages/User/successors/successor-input';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import {
  invalidateSuccessorQueries,
  useCreateInvitation,
  useGetInvitationsList,
} from '#/api/successor';
import { Successor } from '#/types/user';

type Props = {
  closeModal?: () => void;
};
export interface FormValues {
  invitations?: Successor[];
  agree?: boolean;
}
export default function SuccessorForm({ closeModal }: Props) {
  const { translate } = useLocales();
  const { mutateAsync: createInvitation, isLoading } = useCreateInvitation();
  const { data: list } = useGetInvitationsList('SUCCESSOR');

  const SuccessorSchema = Yup.object().shape({
    invitations: Yup.array()
      .of(
        Yup.object().shape({
          first_name: Yup.string().required(
            'validations.successors.first_name'
          ),
          last_name: Yup.string().required('validations.successors.last_name'),
          email: Yup.string()
            .email('validations.successors.invalidEmail')
            .required('validations.successors.email'),
        })
      )
      .min(2, 'validations.successors.min'),
    agree: Yup.boolean().oneOf([true], 'validations.successors.agree'),
  });

  const defaultValues = useMemo(
    () => ({
      invitations: list || [
        {
          first_name: '',
          last_name: '',
          email: '',
          type: 'SUCCESSOR',
        },
      ],
      agree: false,
    }),
    [list]
  );

  const methods = useForm<FormValues>({
    resolver: yupResolver(SuccessorSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invitations',
  });

  const onSubmit = async (data: FormValues) => {
    try {
      await createInvitation(data).then((r) => {
        if (r?.data?.errors[0]) {
          toast.error(r?.data?.errors[0]?.error);
        } else {
          toast.success(
            String(translate('toast_notifications.success.invitation_sent'))
          );
        }
        invalidateSuccessorQueries.getInvitationsList();
        if (!r?.data?.errors[0] && closeModal) {
          closeModal();
        }
      });
    } catch (e) {
      toast.error('Error');
    }
  };

  useEffect(() => {
    if (list) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [list]);

  useEffect(() => {
    if (list?.length === 0) {
      append({ first_name: '', last_name: '', email: '', type: 'SUCCESSOR' });
      append({ first_name: '', last_name: '', email: '', type: 'SUCCESSOR' });
    }
    // eslint-disable-next-line
  }, [list]);

  const showDeleteButton = (email: string) =>
    list?.some((invitation: Successor) => invitation?.email === email);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {fields.map((item, index) => (
        <SuccessorInput
          index={index}
          key={item.id}
          remove={remove}
          id={Number(item?.unique_id)}
          showDeleteButton={showDeleteButton}
        />
      ))}
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        alignItems="center"
        spacing={2}
      >
        <Button
          variant="text"
          color="primary"
          type="button"
          onClick={() =>
            append({
              first_name: '',
              last_name: '',
              email: '',
              type: 'SUCCESSOR',
            })
          }
          startIcon={<Iconify icon="mdi:plus" />}
        >
          <Typography>
            {String(translate('global.userSettings.successor.addSuccessor'))}
          </Typography>
        </Button>
        <Typography variant="body1" color="error">
          {String(translate(errors.invitations?.message))}
        </Typography>
      </Stack>
      <RHFCheckbox
        name="agree"
        label={String(translate('global.userSettings.successor.agree'))}
      />
      <Stack
        direction="row"
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ width: 'auto' }}
          disabled={isLoading}
          startIcon={
            isLoading ? (
              <Iconify icon="line-md:loading-twotone-loop" />
            ) : (
              <Iconify icon="mdi:content-save" />
            )
          }
        >
          <Typography>{String(translate('global.save'))}</Typography>
        </Button>
      </Stack>
    </FormProvider>
  );
}
