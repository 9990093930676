import React, { useEffect, useState } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Page from '#/components/shared/ui/Page';
import Iconify from '#/components/shared/ui/Iconify';
import AccountGeneral from '../../components/pages/User/AccountGeneral';
import AccountBilling from '../../components/pages/User/billing/AccountBilling';
import AccountNotifications from '../../components/pages/User/AccountNotifications';
import AccountChangePassword from '../../components/pages/User/AccountChangePassword';
import useLocales from '#/hooks/useLocales';
import AccountSuccessors from '#/components/pages/User/successors/AccountSuccessors';
import FamilyPack from '#/components/pages/User/billing/family-pack/FamilyPack';
import useAuth from '#/hooks/useAuth';
import AccountDataSharing from '#/components/pages/User/AccountDataSharing';

const UserAccountPage = () => {
  const { search } = useLocation();
  const { translate } = useLocales();
  const { user } = useAuth();

  const isConsultant = user?.partner?.role === 'consultant';

  const isFamily = search?.includes('family=true');
  const isSuccess = search === '?success=true' || search === '?billing=true';

  const [currentTab, setCurrentTab] = useState(
    // eslint-disable-next-line no-nested-ternary
    isFamily ? 'my_family' : isSuccess ? 'billing' : 'general'
  );

  const TABS = [
    {
      value: 'general',
      label: String(translate('global.userSettings.general')),
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral />,
    },
    {
      value: 'billing',
      label: String(translate('global.userSettings.billing')),
      icon: <Iconify icon="ic:round-receipt" />,
      component: <AccountBilling />,
    },
    {
      value: 'my_family',
      label: String(translate('global.userSettings.my_family')),
      icon: <Iconify icon="fa:group" />,
      component: <FamilyPack />,
    },
    {
      value: 'successors',
      label: String(translate('global.userSettings.successors')),
      icon: <Iconify icon="fa6-solid:diagram-successor" />,
      component: <AccountSuccessors />,
    },
    {
      value: 'notifications',
      label: String(translate('global.userSettings.notifications')),
      icon: <Iconify icon="eva:bell-fill" />,
      component: <AccountNotifications />,
    },
    {
      value: 'data_sharing',
      label: String(translate('global.userSettings.dataSharing')),
      icon: <Iconify icon="mingcute:safe-alert-fill" />,
      component: <AccountDataSharing />,
    },
    {
      value: 'change_password',
      label: String(translate('global.userSettings.changePassword')),
      icon: <Iconify icon="ic:round-vpn-key" />,
      component: <AccountChangePassword />,
    },
  ];

  useEffect(() => {
    const handleToast = () => {
      if (search === '?success=true') {
        toast.success(
          String(translate('toast_notifications.success.package_update'))
        );
      }
      if (search === '?failed=true') {
        toast.error(
          String(translate('toast_notifications.error.package_failed'))
        );
      }
    };
    if (search) {
      handleToast();
    }
    // eslint-disable-next-line
  }, [search]);

  const isDisabled = user?.subscription_package !== 'family';

  return (
    <Page title={`${TABS.find((tab) => tab.value === currentTab)?.label} `}>
      <Container maxWidth="lg">
        <Tabs
          scrollButtons
          allowScrollButtonsMobile
          variant="scrollable"
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
        >
          {TABS.filter(
            (tab) =>
              (tab.value !== 'my_family' || !isDisabled) &&
              (!isConsultant
                ? tab
                : tab.value === 'general' || tab.value === 'change_password')
          ).map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
              disabled={tab.value === 'my_family' && isDisabled}
            />
          ))}
        </Tabs>

        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
      </Container>
    </Page>
  );
};

export default UserAccountPage;
