import { useForm } from 'react-hook-form';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useCallback, useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import {
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import ColorPicker from '#/components/shared/custom-input/ColorPicker';
import { fData } from '#/utils/formatNumber';
import {
  useGetPartnerSettings,
  useUpdatePartnerSettings,
} from '#/api/partnerQueries';
import useAuth from '#/hooks/useAuth';

type FormValuesProps = {
  partner_name: string;
  primary_color: string;
  secondary_color: string;
  subdomain: string;
  image: string;
  billing_address: string;
};

export default function AccountPlatform() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const isAdminLoggedIn =
    user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

  const { data: platform } = useGetPartnerSettings(isAdminLoggedIn);
  const { mutateAsync: updatePlatform } = useUpdatePartnerSettings();

  const defaultValues = useMemo(
    () => ({
      partner_name: platform?.partner_name ?? '',
      primary_color: platform?.primary_color ?? '',
      secondary_color: platform?.secondary_color ?? '',
      subdomain: platform?.subdomain ?? '',
      image: platform?.image ?? '',
      billing_address: platform?.billing_address ?? '',
    }),
    [platform]
  );

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    reset,
    watch,
  } = methods;

  const primaryColorChange =
    watch('primary_color') !== defaultValues.primary_color;
  const secondaryColorChange =
    watch('secondary_color') !== defaultValues.secondary_color;
  const subdomainChange = watch('subdomain') !== defaultValues.subdomain;
  const partnerNameChange =
    watch('partner_name') !== defaultValues.partner_name;
  const imageChange = watch('image') !== defaultValues.image;
  const billingEmailChange =
    watch('billing_address') !== defaultValues.billing_address;

  const disableDomain =
    defaultValues.subdomain !== '' && defaultValues.subdomain !== null;

  const disabled =
    !primaryColorChange &&
    !secondaryColorChange &&
    !subdomainChange &&
    !partnerNameChange &&
    !imageChange &&
    !billingEmailChange;

  const onSubmit = async (data: FormValuesProps) => {
    if (JSON.stringify(data) === JSON.stringify(defaultValues)) {
      toast.loading('Nothing to update here...', {
        duration: 1500,
        icon: '🤔',
      });

      return;
    }

    const formData = new FormData();
    formData.append('partner_name', data.partner_name);
    formData.append('primary_color', data.primary_color);
    formData.append('secondary_color', data.secondary_color);
    formData.append('subdomain', data.subdomain);
    formData.append('image', data.image);
    formData.append('billing_address', data.billing_address);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await updatePlatform(formData).then(() => {
      toast.success(
        String(translate('toast_notifications.success.platform_update'))
      );
    });
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (!file) {
        toast.error('No file selected.');
        return;
      }

      const image = new Image();
      image.onload = () => {
        const { naturalWidth, naturalHeight } = image;
        if (naturalWidth < 630 || naturalHeight < 80) {
          toast.error(String(translate('validations.platform_logo')));
        } else {
          const newFile = Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
          // @ts-ignore
          setValue('image', newFile, { shouldValidate: true });
        }
      };
      image.onerror = () => {
        toast.error('Invalid image file.');
      };
      image.src = URL.createObjectURL(file);
    },
    [setValue]
  );

  useEffect(() => {
    if (platform) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [platform]);

  return (
    <>
      <Typography variant="subtitle1" sx={{ px: 3 }}>
        {String(translate('adminDashboard.platform'))}
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              {/* <AccountPicture /> */}
              <RHFUploadAvatar
                name="image"
                maxSize={10000000}
                onDrop={handleDrop}
                sx={{
                  borderRadius: '5%',
                  width: '100%',
                }}
                styledPlaceholderSx={{
                  borderRadius: 1,
                }}
                avatarPreviewSx={{
                  borderRadius: '5%',
                  height: 'fit-content',
                }}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    {String(translate('global.imageHelper'))}
                    <br /> {String(translate('global.imageMaxSize'))}{' '}
                    {fData(10000000)}
                  </Typography>
                }
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={1}
                >
                  <RHFTextField
                    name="partner_name"
                    label={String(
                      translate('global.formLabels.organisation_name')
                    )}
                  />
                </Stack>
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={1}
                >
                  <RHFTextField
                    disabled={disableDomain}
                    name="subdomain"
                    label={String(translate('global.formLabels.domain'))}
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body1" color="text.secondary">
                          .gutgeregelt.ch
                        </Typography>
                      ),
                    }}
                  />
                  <RHFTextField
                    name="billing_address"
                    label={String(translate('global.formLabels.billing_email'))}
                  />
                </Stack>
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={1}
                >
                  <ColorPicker
                    name="primary_color"
                    label={String(translate('global.formLabels.primary_color'))}
                  />
                  <ColorPicker
                    name="secondary_color"
                    label={String(
                      translate('global.formLabels.secondary_color')
                    )}
                  />
                </Stack>
                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                  <LoadingButton
                    aria-label="save changes"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={disabled}
                  >
                    <Typography>{String(translate('global.save'))}</Typography>
                  </LoadingButton>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
